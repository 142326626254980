import React from "react";
import {
  ExternalLinkIcon,
  UploadIcon,
  DownloadIcon,
  PencilIcon,
  PaperClipIcon,
  DocumentAddIcon,
  ShareIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PhotographIcon,
} from "@heroicons/react/outline";
import {
  ArrowRightIcon,
  ChatIcon,
  EyeIcon,
  MailIcon,
  TrashIcon,
  PlusIcon,
  QrcodeIcon,
  PlayIcon,
} from "@heroicons/react/solid";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

export interface SecondaryButtonProps {
  text: string;
  size: string;
  onClickFunc?: React.MouseEventHandler<HTMLButtonElement>;
  width?: string;
  height?: string;
  spacing?: string;
  type?: "button" | "reset" | "submit";
  href?: string;
  disabled?: boolean;
  loading?: boolean;
  warning?: boolean;
  cancel?: boolean;
  icon?: string | JSX.Element;
  iconPlacement?: string;
  id?: string;
  customStyle?: string;
  mainIcon?: string | JSX.Element;
  iconStyle?: string | JSX.Element;
  secondaryIcon?: string | JSX.Element;
}

export default function SecondaryButton({
  text,
  size,
  onClickFunc,
  disabled,
  loading,
  warning,
  cancel,
  icon,
  iconPlacement,
  id,
  customStyle,
  children,
  mainIcon,
  iconStyle,
  secondaryIcon,
}: React.PropsWithChildren<SecondaryButtonProps>): React.ReactElement {
  let style;
  let cssStyle;
  const spinnerSize = size;

  if (loading) {
    style = "bg-white text-blue-900 border border-blue-900";
  } else if (disabled) {
    style =
      "bg-white opacity-50 text-gray-700 border border-gray-300 cursor-not-allowed";
  } else if (warning) {
    style = "bg-red-100 text-red-600 border border-red-600";
  } else if (cancel) {
    style = "bg-white text-gray-600 border border-gray-900";
  } else {
    style = `bg-white text-gray-700 border border-gray-300 hover:bg-gray-50 active:ring-2 active:ring-offset-2 active:ring-blue-900 focus:bg-white transition-colors duration-150 ease-in-out`;
  }

  iconStyle = iconStyle ? iconStyle : `text-gray-500`;
  let textSize = "";
  function getIconStyle(size: string) {
    if (size === "xs" || size === "sm")
      return `h-4 w-4 ${iconStyle} ${
        iconPlacement === "leading" ? "mr-2" : "ml-2"
      }`;

    if (size === "md")
      return `h-5 w-5 ${iconStyle} ${
        iconPlacement === "leading" ? "mr-2" : "ml-2"
      }`;
    else
      return `h-5 w-5 ${iconStyle} ${
        iconPlacement === "leading" ? "mr-3" : "ml-3"
      }`;
  }

  switch (size) {
    case "xxs":
      textSize = "text-xs";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "7px" : "9px",
        paddingRight: icon && iconPlacement === "lagging" ? "7px" : "9px",
        paddingTop: "3px",
        paddingBottom: "3px",
      };
      iconStyle = getIconStyle("xs");
      break;
    case "xs":
      textSize = "text-xs";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "9px" : "11px",
        paddingRight: icon && iconPlacement === "lagging" ? "9px" : "11px",
        paddingTop: "5px",
        paddingBottom: "5px",
      };
      iconStyle = getIconStyle("xs");
      break;
    case "sm":
      textSize = "text-xs";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "11px" : "13px",
        paddingRight: icon && iconPlacement === "lagging" ? "11px" : "13px",
        paddingTop: "7px",
        paddingBottom: "7px",
      };
      iconStyle = getIconStyle("sm");
      break;
    case "md":
      textSize = "text-sm";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "15px" : "17px",
        paddingRight: icon && iconPlacement === "lagging" ? "15px" : "17px",
        paddingTop: "9px",
        paddingBottom: "9px",
      };
      iconStyle = getIconStyle("md");
      break;
    case "md extra padding":
      textSize = "text-sm";
      cssStyle = {
        paddingLeft: "40px",
        paddingRight: "40px",
        paddingTop: "9px",
        paddingBottom: "9px",
      };
      iconStyle = getIconStyle("md");
      break;
    case "lg":
      textSize = "text-base";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "15px" : "17px",
        paddingRight: icon && iconPlacement === "lagging" ? "15px" : "17px",
        paddingTop: "9px",
        paddingBottom: "9px",
      };
      iconStyle = getIconStyle("lg");
      break;
    case "lg extra padding":
      textSize = "text-base";
      cssStyle = {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "9px",
        paddingBottom: "9px",
      };
      iconStyle = getIconStyle("lg");
      break;
    case "xl":
      textSize = "text-base";
      cssStyle = {
        paddingLeft: icon && iconPlacement === "leading" ? "23px" : "25px",
        paddingRight: icon && iconPlacement === "lagging" ? "23px" : "25px",
        paddingTop: "13px",
        paddingBottom: "13px",
      };
      iconStyle = getIconStyle("xl");
      break;
  }

  if (icon) {
    switch (icon) {
      case "chat":
        icon = <ChatIcon className={iconStyle} />;
        break;
      case "mail":
        icon = <MailIcon className={iconStyle} />;
        break;
      case "external-link":
        icon = <ExternalLinkIcon className={iconStyle} />;
        break;
      case "plus":
        icon = <PlusIcon className={iconStyle} />;
        break;
      case "delete":
        icon = <TrashIcon className={iconStyle} />;
        break;
      case "upload":
        icon = <UploadIcon className={iconStyle} />;
        break;
      case "export":
        icon = <DownloadIcon className={iconStyle} />;
        break;
      case "right-arrow":
        icon = <ArrowRightIcon className={iconStyle} />;
        break;
      case "paperclip":
        icon = <PaperClipIcon className={iconStyle} />;
        break;
      case "qr":
        icon = <QrcodeIcon className={iconStyle} />;
        break;
      case "pencil":
        icon = <PencilIcon className={iconStyle} />;
        break;
      case "document-add":
        icon = <DocumentAddIcon className={iconStyle} />;
        break;
      case "share":
        icon = <ShareIcon className={iconStyle} />;
        break;
      case "play":
        icon = <PlayIcon className={iconStyle} />;
        break;
      case "eye":
        icon = <EyeIcon className={iconStyle} />;
        break;
      case "chevron-down-arrow":
        icon = <ChevronDownIcon className={iconStyle} />;
        break;
      case "chevron-right-arrow":
        icon = <ChevronRightIcon className={iconStyle} />;
        break;
      case "photo":
        icon = <PhotographIcon className={iconStyle} />;
        break;
    }
  }
  if (mainIcon) {
    switch (mainIcon) {
      case "Google":
        mainIcon = (
          <div className="w-5 h-5">
            <img src="/google-logo.png" alt="Google Logo" />
          </div>
        );
        break;
      case "Facebook":
        mainIcon = (
          <div className="w-5 h-5">
            <img src="/facebook_logo.png" alt="Facebook Logo" />
          </div>
        );
        break;
    }
  }

  if (customStyle) {
    style = style + " " + customStyle;
  }

  return (
    <>
      <button
        id={id || ""}
        type="button"
        data-cy={id}
        disabled={disabled}
        className={`${textSize} ${style} flex justify-center items-center rounded-full font-medium shadow`}
        style={cssStyle}
        onClick={onClickFunc}
        autoFocus={false}
        data-testid="secondary-button"
      >
        {secondaryIcon && secondaryIcon}
        {iconPlacement === "leading" && icon}
        {loading ? (
          <LoadingSpinner
            size={
              spinnerSize === "lg" || spinnerSize === "xl" ? "big-spinner" : ""
            }
            color="blue"
          />
        ) : (
          <>
            <div className="">{text}</div>
            <div> {mainIcon && mainIcon}</div>
          </>
        )}
        <div>{iconPlacement === "lagging" && icon}</div>
        {children}
      </button>
    </>
  );
}
