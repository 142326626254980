import { GiftIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";
import React, { createContext, useEffect, useState } from "react";
import {
  GiftIcon as SelectedGiftIcon,
  QuestionMarkCircleIcon as SelectedQuestionMarkCircleIcon,
} from "@heroicons/react/solid";
import {
  CalendarIcon,
  CogIcon,
  CreditCardIcon,
  DesktopComputerIcon,
  HomeIcon,
  ThumbsUpIcon,
  SelectedThumbsUpIcon,
  SelectedCalendarIcon,
  SelectedCogIcon,
  SelectedCreditCardIcon,
  SelectedDesktopComputerIcon,
  SelectedHomeIcon,
  SelectedStarIcon,
  SelectedUserGroupIcon,
  StarIcon,
  UserGroupIcon,
  AdsIcon,
  SelectedAdsIcon,
} from "../../components/common/Icons/Icons";

export interface NavigationFrame {
  name: string;
  /**
   * Icon is a hero icon JSX component from Tailwinds Hero Icon Kit
   */
  icon: JSX.Element;
  selectedIcon: JSX.Element;
  selected: boolean;
  href: string;
  onClick?: () => void;
  target?: string;
}

const navigation = [
  {
    name: "Home",
    icon: HomeIcon,
    selectedIcon: SelectedHomeIcon,
    selected: true,
    href: "/home",
  },
  {
    name: "Social Media",
    icon: ThumbsUpIcon,
    selectedIcon: SelectedThumbsUpIcon,
    selected: true,
    href: "/social",
  },
  {
    name: "My Site",
    icon: DesktopComputerIcon,
    selectedIcon: SelectedDesktopComputerIcon,
    selected: false,
    href: "/site",
  },
  {
    name: "Reviews",
    icon: StarIcon,
    selectedIcon: SelectedStarIcon,
    selected: false,
    href: "/reviews",
  },
  {
    name: "Marketing",
    icon: AdsIcon,
    selectedIcon: SelectedAdsIcon,
    selected: false,
    href: "/marketing",
  },

  {
    name: "Bookings",
    icon: CalendarIcon,
    selectedIcon: SelectedCalendarIcon,
    selected: false,
    href: "/bookings",
  },
  {
    name: "Payments",
    icon: CreditCardIcon,
    selectedIcon: SelectedCreditCardIcon,
    selected: false,
    href: "/payments",
  },
  {
    name: "Contacts",
    icon: UserGroupIcon,
    selectedIcon: SelectedUserGroupIcon,
    selected: false,
    href: "/contacts",
  },
];

export const bottomLeftOptions = [
  {
    name: "Get $100",
    icon: GiftIcon,
    selectedIcon: SelectedGiftIcon,
    selected: false,
    href: `/settings/refer-a-friend`,
  },
  {
    name: "Settings",
    icon: CogIcon,
    selectedIcon: SelectedCogIcon,
    selected: false,
    href: "/settings",
  },
  {
    name: "Help & Support",
    icon: QuestionMarkCircleIcon,
    selectedIcon: SelectedQuestionMarkCircleIcon,
    selected: false,
    href: "https://help.toplinepro.com/",
    target: "_blank",
  },
];

export const BillingIntervals = {
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
};

interface AppContextType {
  handleSelectedFrame: (_name: string) => void;
  selectedFrame: Array<NavigationFrame>;
  bottomLeftNavigation: Array<NavigationFrame>;
  helpModal: boolean;
  setHelpModal: (_isOpen: boolean) => void;
  authStatus: boolean;
  setAppProfileInfo: (_data: any) => void;
  appProfileInfo: any;
}

export const AppContext = createContext({} as AppContextType);

export const AppProvider = ({ children }: any) => {
  const [selectedFrame, setSelectedFrame] = useState(navigation);
  const [helpModal, setHelpModal] = useState(false);
  const [authStatus] = useState(false);
  const [appProfileInfo, setAppProfileInfo] = useState<any>();
  const [bottomLeftNavigation, setBottomLeftNavigation] = useState<any>([]);

  useEffect(() => {
    const bottomLeft = [
      {
        name: "Settings",
        icon: CogIcon,
        selectedIcon: SelectedCogIcon,
        selected: false,
        href: "/settings",
      },
      {
        name: "Help & Support",
        icon: QuestionMarkCircleIcon,
        selectedIcon: SelectedQuestionMarkCircleIcon,
        selected: false,
        href: "https://help.toplinepro.com/",
        target: "_blank",
      },
    ];
    setBottomLeftNavigation(bottomLeft);
    handleSelectedFrame();
  }, [appProfileInfo]);

  function handleSelectedFrame() {
    setSelectedFrame(navigation);
  }

  const contextValues = {
    handleSelectedFrame,
    selectedFrame,
    bottomLeftNavigation,
    helpModal,
    setAppProfileInfo,
    appProfileInfo,
    setHelpModal,
    authStatus,
  };
  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  );
};
