import { URL_CONSTANTS } from "../../../../constants/urlConstants";
import { Review } from "../../../../contexts/types";
import { api } from "../../../../helpers/topline-api";

export const tooltipTextForAutoResponses =
  "Example: Hey Lori, thank you so much for the kind words. You were a pleasure to work for and I love the design you settled on. Your room is beautiful!";

export async function testimonialsBeta(
  reviewPk: string,
  testimonialProperties: Partial<Review>
) {
  try {
    const response = await api.post(`/api/testimonials-beta`, {
      pk: reviewPk,
      ...testimonialProperties,
    });
    return response?.data;
  } catch (error) {
    // Handle error
    console.error("An error occurred while fetching data: ", error);
    throw error;
  }
}

export async function publishRoute() {
  return api.get(`/api/publish_route`);
}

export function formatDate(date: Date) {
  return date.toLocaleString("en-US", { month: "short", day: "numeric" });
}

export function formatDateLong(date: Date) {
  return date.toLocaleString("en-US", { month: "long", day: "numeric" });
}

export function formatDateTime(date: Date) {
  return date.toLocaleString("en-US", {
    weekday: "long",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
}

export function filterByRange(days, allItems) {
  const filteredItems = allItems?.filter(function (item) {
    const reviewDate = new Date(item?.created_at || item?.date_posted);
    const now = new Date();
    const msSince = Math.abs(reviewDate.getTime() - now.getTime());
    const daysSince = msSince / (24 * 60 * 60 * 1000);
    return daysSince < days;
  });
  return filteredItems;
}

export function getAverageRating(items: Review[], numToFixed = 2) {
  const reviewCount = items?.length;

  const averageRating =
    items?.reduce((sum, item) => sum + parseInt(item?.rating), 0) / reviewCount;
  const formattedRating = averageRating?.toFixed(numToFixed);
  return formattedRating === "NaN" ? "No reviews" : formattedRating;
}

const date1 = new Date();
export const yesterday = new Date(
  date1.getFullYear(),
  date1.getMonth(),
  date1.getDate() - 1
);
export const sevenDaysAgo = new Date(
  date1.getFullYear(),
  date1.getMonth(),
  date1.getDate() - 6
);
export const thirtyDaysAgo = new Date(
  date1.getFullYear(),
  date1.getMonth(),
  date1.getDate() - 29
);
const date2 = new Date();
date2.setDate(date2.getDate() - ((date2.getDay() + 6) % 7));
date2.setDate(date2.getDate() - 7);
export const lastMonday = new Date(
  date2.getFullYear(),
  date2.getMonth(),
  date2.getDate()
);
export const lastSunday = new Date(
  date2.getFullYear(),
  date2.getMonth(),
  date2.getDate() + 6
);
export const firstDayLastMonth = new Date(
  date1.getFullYear(),
  date1.getMonth() - 1,
  1
);
export const lastDayLastMonth = new Date(
  date1.getFullYear(),
  date1.getMonth(),
  0
);
const date4 = new Date();
const first = date4.getDate() - date4.getDay() + 1;
export const firstDayThisWeek = new Date(date4.setDate(first));
export const firstDayThisMonth = new Date(
  date4.getFullYear(),
  date4.getMonth(),
  1
);

export async function postReviewRequest(
  slug: string,
  type: any,
  contactIds: any,
  nonContactRecipients: any = [],
  localRequestReviewMessage: any,
  pendingUntilGoogleVerified: boolean = false
) {
  const response = await api.post(`/api/post-review-request`, {
    headers: {
      "Content-Type": "application/json",
    },
    slug: slug,
    type: type,
    contact_ids: contactIds,
    non_contact_recipients: nonContactRecipients,
    modified_review_request: localRequestReviewMessage,
    pending_until_google_verified: pendingUntilGoogleVerified,
  });

  return response.data.message;
}

export const getNewFbReviews = async (sitePk: string) => {
  try {
    const response = await api.post(`/api/get_new_fb_reviews_by_landing_page`, {
      site_pk: sitePk,
    });
    if (response.data.status !== "success") {
      return [];
    }
    return response?.data?.message?.data;
  } catch (e) {
    return [];
  }
};

export const getNewGmbReviews = async (sitePk: string) => {
  const response = await api.post(`/api/get_new_gmb_reviews_by_landing_page`, {
    headers: {
      "Content-Type": "application/json",
    },
    site_pk: sitePk,
  });
  if (response.data.status !== "success") {
    return [];
  }
  return response?.data?.message?.data;
};

export async function getAllReviewRequests(slug: any) {
  if (slug) {
    const response = await api.get(`/api/get-review-requests/${slug}`);
    return response.data.message;
  }
  return;
}

export async function getAllReviews(slug: any) {
  if (slug) {
    const response = await api.get(`/api/get-reviews/${slug}`);
    return response.data.message;
  }
  return;
}

export function getFormatedDate(date: string) {
  const d = new Date(date);
  const month = d.toLocaleDateString("default", {
    month: "short",
  });
  const day = d.getDate();
  const year = d.getFullYear();
  return `${month} ${day}, ${year}`;
}

export async function postReplyToGoogleReview(
  comment: string,
  slug: any,
  review_id: any
) {
  try {
    const response = await api.post(
      URL_CONSTANTS.PROSITE_POST_REPLY_TO_GOOGLE_REVIEW,
      {
        comment,
        slug,
        review_id,
      }
    );
    return response?.data;
  } catch (error) {
    console.log("error posting reply to review onto social media");
    return { status: "error" };
  }
}

export async function deleteReplyToGoogleReview(slug: any, review_id: any) {
  try {
    const response = await api.delete(
      URL_CONSTANTS.PROSITE_DELETE_REPLY_TO_GOOGLE_REVIEW,
      { data: { slug, review_id } }
    );
    return response?.data;
  } catch (error) {
    console.log("error deleting reply to review onto social media");
    return { status: "error" };
  }
}

export async function getShowAutoReplyToReviewBannerFunc(slug: any) {
  try {
    const response = await api.get(
      `${URL_CONSTANTS.PROSITE_GET_AUTO_REPLY_TO_REVIEW_BANNER}/${slug}`
    );
    return response?.data?.message;
  } catch (error) {
    console.log("error getShowAutoReplyToReviewBannerFunc");
    return false;
  }
}

export async function updateShowAutoReplyToReviewBanner(slug: any) {
  try {
    const response = await api.post(
      `${URL_CONSTANTS.PROSITE_UPDATE_SHOW_AUTO_REPLY_TO_REVIEW_BANNER}`,
      { slug }
    );
    return response?.data?.message;
  } catch (error) {
    console.log("error updateShowAutoReplyToReviewBanner");
    return false;
  }
}

export async function generateReplyToSocialMediaReview(
  slug: any,
  reviewer_name: any,
  number_of_stars: any,
  review: any
) {
  const url = `/api/generate_gpt3_reply_to_social_media_review`;
  const response = await api.post(url, {
    slug,
    reviewer_name,
    number_of_stars,
    review,
  });

  if (response.data.message === "error") {
    return "";
  } else {
    return response.data.message;
  }
}

export async function handleAutoReplyToReview(
  slug: any,
  reply_to_existing_reviews: boolean,
  new_toggle_status: boolean
) {
  const response = await api.put(`/api/toggle_auto_reply_to_review`, {
    slug,
    reply_to_existing_reviews,
    new_toggle_status,
  });

  return response;
}
