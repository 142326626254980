import { api } from "../../../helpers/topline-api";
import { PlanType } from "../../../contexts/types";
import { StripePlans } from "./productTypes";

const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT;

export async function fetchPlans() {
  const response = await api.get("/api/stripe-get-all-plans");
  const responseData = response?.data?.message;
  return responseData;
}

function getPlan(stripePlans: any, tier: string, planId: string) {
  if (!stripePlans[tier]) {
    // TODO: Load stripePlans before to avoid this
    console.log("Error: stripe plans haven't loaded yet");
    return "";
  }

  return stripePlans[tier].data.filter((plan: any) => plan.id === planId)[0];
}

export function getEmailPrice(
  stripePlans: any,
  isAnnual: boolean
): number | null {
  if (!stripePlans) {
    return null;
  }

  let emailPlanId;

  if (isAnnual) {
    emailPlanId =
      ENVIRONMENT === "development"
        ? "price_1KZ0DEKohomEkgjrNmJZ97pD" // TODO: dev
        : "price_1KZ0DEKohomEkgjrNmJZ97pD";
  } else {
    emailPlanId =
      ENVIRONMENT === "development"
        ? "price_1KZ0DEKohomEkgjrssqO5O0U" // TODO: dev
        : "price_1KZ0DEKohomEkgjrssqO5O0U";
  }

  const emailPlan = getPlan(stripePlans, "core", emailPlanId);
  if (emailPlan) {
    return emailPlan.unit_amount / 1000;
  } else {
    return null;
  }
}

export const adsMonthlyId =
  ENVIRONMENT === "development"
    ? "price_1Mnli2KohomEkgjrHcUZyhxd"
    : "price_1MMhUVKohomEkgjrkeaNoWHw";

export const newEmailMonthlyId =
  ENVIRONMENT === "development"
    ? "price_1P5sYKKohomEkgjrAme2DaZQ"
    : "price_1P7GWOKohomEkgjrdHLZs2j9";

export const oldEmailMonthlyId =
  ENVIRONMENT === "development"
    ? "price_1KZ0GTKohomEkgjrBtF14fNw"
    : "price_1K5CzxKohomEkgjrlaA4Hdjk";

export const emailAnnualId =
  ENVIRONMENT === "development"
    ? "price_1P7GboKohomEkgjrwU4vvuzl"
    : "price_1P7GaiKohomEkgjre0UynmWi";

export function withAmounts(
  plans: PlanType[],
  stripePlans: StripePlans
): PlanType[] {
  return plans.map((p: PlanType) => {
    const plan = getPlan(stripePlans, p.type, p.price);
    p.amount = plan.unit_amount / 100;
    return p;
  });
}

export const defaultPlans = [
  {
    type: "starter",
    billing_cycle: "monthly",
    price:
      ENVIRONMENT === "development"
        ? "price_1OIdmZKohomEkgjrz9garyN4"
        : "price_1OIe51KohomEkgjrwluunXmG",
    amount: 199,
  },
  {
    type: "starter",
    billing_cycle: "annual",
    price:
      ENVIRONMENT === "development"
        ? "price_1OIdnNKohomEkgjreXiUriw9"
        : "price_1OIe5HKohomEkgjr11UOO0jc",
    amount: 1920,
  },
  {
    type: "core",
    billing_cycle: "monthly",
    price:
      ENVIRONMENT === "development"
        ? "price_1OIdo7KohomEkgjr1v1G7zio"
        : "price_1OIe5nKohomEkgjryM7l8rPH",
    amount: 249,
  },
  {
    type: "core",
    billing_cycle: "annual",
    price:
      ENVIRONMENT === "development"
        ? "price_1OIdoYKohomEkgjr4inkDV6k"
        : "price_1OIe5zKohomEkgjryrR6Ynf5",
    amount: 2400,
  },
  {
    type: "pro",
    billing_cycle: "monthly",
    price:
      ENVIRONMENT === "development"
        ? "price_1OyG4pKohomEkgjrgo2oEUZd"
        : "price_1OyG5WKohomEkgjrAvjpmsBW",
    amount: 299,
  },
  {
    type: "pro",
    billing_cycle: "annual",
    price:
      ENVIRONMENT === "development"
        ? "price_1OIdpgKohomEkgjrNu9tDNFj"
        : "price_1OIe6eKohomEkgjrUBdbvaYP",
    amount: 3840,
  },
];
